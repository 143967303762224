import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Breadcrumbs, Icon, LatestNews, showError } from 'components';
import { getNewsDetails } from 'services';
import { dateFormat, getImagePath } from 'utils';
import { header, body, separator } from './styles';

const NewsDetails = () => {
  const { getText } = useTranslations();
  const params = useParams();
  const newsId = params?.newsId;
  const [newsData, setNewsData] = useState();

  const items = [
    {
      label: getText('news'),
      url: '/news',
    },
    {
      label: newsData?.title,
    },
  ];

  useEffect(() => {
    fetchNews();
  }, [newsId]);

  const fetchNews = async () => {
    const [res, err] = await getNewsDetails(newsId);
    if (err) return showError(err?.data);
    setNewsData(res);
  };

  return (
    <>
      <section css={header(getImagePath(newsData))}>
        <div className="header-inner">
          <Breadcrumbs items={items} className="header-breadcrumbs" />
          <Link to="/news">
            <div className="go-back-to-all">
              <Icon iconName="la la-arrow-left" />
              <p>{getText('allNews')}</p>
            </div>
          </Link>

          <Row justify="space-between" horizontalGap={16} className="content-row">
            <Col lg={6} xxl={5} xxxl={5}>
              <h2 className="news-title">{newsData?.title}</h2>
              <p className="news-text">{newsData?.description}</p>
              <p className="news-date">
                {getText('published')}: {dateFormat(newsData?.created)}
              </p>
            </Col>
            <Col lg={6} xxl={6} xxxl={5}>
              <div className="news-image" />
            </Col>
          </Row>
        </div>
      </section>
      <section css={body}>
        <Row container>
          <Col>
            <div className="news-content" dangerouslySetInnerHTML={{ __html: newsData?.content }} />
          </Col>
        </Row>
      </section>
      <div css={separator} />
      {newsData?.categoryId && <LatestNews titleKey="relatedNews" noSlider categoryId={newsData?.categoryId} />}
    </>
  );
};

export default NewsDetails;
