import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Row, Col, NewsList, showError } from 'components';
import { getNews } from 'services';
import { dateFormat, getImagePath } from 'utils';
import { header } from './styles';

const News = () => {
  const { getText } = useTranslations();
  const [mainNews, setMainNews] = useState();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    const [res, err] = await getNews({
      filter: '(isMain=true && isActive=true)',
    });
    if (err) return showError(err?.data);
    if (res?.totalItems > 0) setMainNews(res?.items?.at(0));
  };

  return (
    <>
      <Row container>
        <Col>
          <section css={header(getImagePath(mainNews))}>
            <h2>{getText('oneEcosystemNews')}</h2>
            <div className="top-news">
              <Row horizontalGap={32} align="center">
                <Col md={6} lg={5}>
                  <div className="image-holder" />
                </Col>
                <Col md={6} lg={7}>
                  <div className="content-holder">
                    <p className="news-date">{dateFormat(mainNews?.created)}</p>
                    <h4 className="news-title">{mainNews?.title}</h4>
                    <p className="news-text">{mainNews?.description}</p>
                    <Button linkTo={`/news/${mainNews?.id}`} type="secondary">
                      {getText('readMore')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          <NewsList />
        </Col>
      </Row>
    </>
  );
};

export default News;
