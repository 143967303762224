import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Row, Col, Icon, showError } from 'components';
import { getGlobalCouncilsDetails } from 'services';
import { getImagePath } from 'utils';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe-icon.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/envelope-icon.svg';
import { body, header, memberImage } from './styles';

const MemberDetails = () => {
  const { getText } = useTranslations();
  const history = useHistory();
  const params = useParams();
  const memberId = params?.memberId;
  const [member, setMember] = useState();

  useEffect(() => {
    fetchMember();
  }, [memberId]);

  const fetchMember = async () => {
    const [res, err] = await getGlobalCouncilsDetails(memberId, { expand: 'regionId' });
    if (err) return showError(err?.data);
    setMember(res);
  };

  return (
    <>
      <div css={header}>
        <Row container>
          <Col>
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}>
              <div className="go-back-to-all">
                <Icon iconName="la la-arrow-left" />
                <p>{getText('goBack')}</p>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
      <div css={body}>
        <Row container>
          <Col md={6} lg={6} xl={6}>
            <div css={memberImage(getImagePath(member))} />
          </Col>
          <Col md={6} lg={6} xl={6}>
            <div className="member-info">
              <h3 className="member-name">{member?.name}</h3>
              <div className="separator" />
              <div className="icon-info">
                <GlobeIcon />
                <p>{`${member?.expand?.regionId?.name}`}</p>
              </div>
              <div className="separator" />
              <div className="member-details" dangerouslySetInnerHTML={{ __html: member?.content }} />
              <a className="member-contact-button" href={`mailto:${member?.email}`}>
                <EnvelopeIcon />
                <p>{member?.email}</p>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MemberDetails;
