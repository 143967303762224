import mainLarge from 'assets/images/main.jpg';

export const homeSectionOne = (theme) => ({
  height: '80vh',
  minHeight: 600,
  padding: '0 4em',
  backgroundColor: theme.secondaryDark,
  backgroundImage: `url(${mainLarge})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  alignItems: 'center',
  '& .inner-intro': {
    width: '100%',
    '& .left-side': {
      textTransform: 'uppercase',
      '& > h4': {
        fontFamily: 'Inter-Regular',
        marginBottom: '0.5em',
      },
      '& > h1': {
        maxWidth: '35rem',
      },
    },
  },

  '@media (max-width: 991px)': {
    height: 'auto',
    minHeight: 'auto',
    padding: '6em 4em',
    '& .main-row': {
      flexDirection: 'column-reverse',
      '& .left-side': {
        textAlign: 'center',
        '& > h4': {
          marginBottom: '1em',
        },
        '& > h1': {
          maxWidth: '100%',
        },
      },
      '& .one-logo': {
        marginBottom: '4em',
      },
    },
  },
  '@media (max-width: 767px)': {
    padding: '6em 2em',
  },
});

export const homeSectionTwo = (theme) => ({
  padding: '6em 4em',
  '& .left-side': {
    '& > h2': {
      textTransform: 'uppercase',
      marginBottom: '0.5em',
    },
    '& > p': {
      fontSize: '1.2rem',
    },
  },
  '& .right-side': {
    '& .item': {
      marginBottom: '3em',
      display: 'flex',
      alignItems: 'flex-start',
      '& > svg': {
        maxWidth: '1rem',
        width: '100%',
        marginRight: '1.5em',
      },
      '& h5': {
        color: theme.secondaryDark,
        marginBottom: '0.5em',
      },
      '& p': {
        color: theme.secondaryDark,
        fontSize: '0.9rem',
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
  },

  '@media (max-width: 767px)': {
    padding: '6em 2em',
    '& .left-side': {
      marginBottom: '3em',
    },
    '& .right-side .item > svg ': {
      display: 'none',
    },
  },
});

export const homeSectionThree = (theme) => ({
  marginBottom: '10em',
  '& > div': {
    backgroundColor: theme.primaryDark,
    padding: 0,
    '&  .section-tree-item-text': {
      backgroundColor: theme.primaryDark,
      padding: '4em',
      '& > h3': {
        textTransform: 'uppercase',
        marginBottom: '0.7em',
        fontFamily: 'Inter-Regular',
      },
    },
  },
  '@media (max-width: 991px)': {
    marginBottom: '6em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
  '@media (max-width: 767px)': {
    marginBottom: '3em',
    '&  > div .section-tree-item-text': {
      padding: '2em',
    },
    '&  > div:nth-of-type(odd) > div': {
      flexDirection: 'column-reverse',
    },
  },
});

export const sectionTreeImageBox = (url) => ({
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  '@media (max-width: 767px)': {
    height: '30rem',
  },
});

export const goToButton = (theme) => ({
  borderTop: `1px solid ${theme.primary}`,
  padding: '2em 4em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&  > p ': {
    margin: 0,
    textTransform: 'uppercase',
  },
  '&  > svg ': {
    maxWidth: '1.5rem',
  },

  '&:hover': {
    backgroundColor: theme.secondaryDarker,
  },
  '@media (max-width: 767px)': {
    padding: '2em',
  },
});
