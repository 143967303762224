import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { ArrowLink } from 'components';
import { getImagePath, dateFormat } from 'utils';
import { wrap, newsImage } from './styles';

const NewsBox = ({ data, className }) => {
  const { getText } = useTranslations();

  return (
    <div css={wrap} {...(className && { className })}>
      <div className="news-image-wrap">
        <div css={newsImage(getImagePath(data))} />
      </div>
      <div className="news-text-wrap">
        <div>
          <p className="news-date">{dateFormat(data?.created)}</p>
          <h6 className="news-title">{data?.title}</h6>
          <p className="news-text">{data?.description}</p>
        </div>
        <ArrowLink title={getText('readMore')} linkTo={`/news/${data?.id}`} size="small" />
      </div>
    </div>
  );
};

NewsBox.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default NewsBox;
