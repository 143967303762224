import { isNil, isEmpty } from 'lodash-es';

export const getImagePath = (data, isMobile) => {
  if (!data || isEmpty(data) || isNil(data)) return;
  const { collectionId, id, image, mobileImage } = data;
  if (!collectionId || !id || !image) return '';
  return `${pocketbaseUrl}/api/files/${collectionId}/${id}/${isMobile ? mobileImage : image}`;
};

export const filterModel = (data) => (!isEmpty(data) ? `(${Object.values(data).join(' && ')})` : null);
