import { useState, useEffect } from 'react';
import { useTranslations } from '@veraio/strank';
import { useParams } from 'react-router-dom';
import { Button } from 'components';
import { products } from 'enums';
import { header, body, footer } from './styles';

const Products = () => {
  const { getText } = useTranslations();
  const params = useParams();
  const productId = params?.productId;
  const [productData, setProductData] = useState();

  useEffect(() => {
    setProductData(products?.find((el) => el?.id === Number(productId)));
  }, [productId]);

  const handleGoToProductWebsite = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <section css={header(productData?.bgImage)}>
        <div className="left-side product-col">
          {productData?.upcoming && (
            <div className="label">
              <span>{getText('upcomingProject')}</span>
            </div>
          )}
          <h1>{productData?.name}</h1>
          <p data-strank-key={productData?.sloganTextKey}>{getText(productData?.sloganTextKey)}</p>
          <p data-strank-key={productData?.productData?.descriptionTitleKey}>
            {getText(productData?.descriptionTitleKey)}
          </p>
          {productData?.website && (
            <Button arrow onClick={() => handleGoToProductWebsite(productData?.website)} type="secondary">
              {getText('openWebsite')}
            </Button>
          )}
        </div>
        <div className="right-side product-col">
          <img src={productData?.imageDarkNoText} alt={`${productData?.name}-logo`} className="product-image" />
        </div>
      </section>
      <section css={body}>
        <div className="body-buffer" />
        <div className="content-holder">
          <div className="content-inner">
            <div className="description">
              <p data-strank-key={productData?.descriptionTextKey}>{getText(productData?.descriptionTextKey)}</p>
            </div>
          </div>
        </div>
        <div className="body-buffer" />
      </section>
      <section css={footer}>
        <div className="left-side">
          <div className="image-holder">
            <img src={productData?.imageDarkNoText} alt={`${productData?.name}-logo`} className="product-image" />
          </div>
          <h3>{productData?.name}</h3>
        </div>
        {productData?.website && (
          <Button arrow className="footer-button" onClick={() => handleGoToProductWebsite(productData?.website)}>
            {getText('openWebsite')}
          </Button>
        )}
      </section>
    </>
  );
};

export default Products;
