export const wrap = (theme) => ({
  backgroundColor: theme.primaryDark,
  padding: '6em 0',
  '& .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.primary}`,
    paddingBottom: '1.5em',
    marginBottom: '2em',
    '&  .title': {
      textTransform: 'uppercase',
      fontFamily: 'Inter-Medium',
      marginBottom: '1em',
      color: theme.secondaryDarker,
      margin: 0,
    },
  },
  '@media (max-width: 991px)': {
    '&  > div ': {
      maxWidth: '100%',
      padding: '0 2em',
    },
  },
});

export const eventWrap = (theme) => ({
  marginBottom: '1em',
  '& .date-wrap ': {
    backgroundColor: theme.secondaryDark,
    textAlign: 'center',
    padding: '2em',
    borderRadius: 3,
    height: '100%',
    width: '8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '&  p ': {
      color: theme.primaryDarker,
      textTransform: 'uppercase',
      marginBottom: '0.2em',
    },
  },
  '& .event-data-wrap ': {
    height: '100%',
    border: `1px solid ${theme.border}`,
    backgroundColor: theme.primaryDarker,
    borderRadius: 3,
    padding: '1em',
    '& .event-data ': {
      '& .label ': {
        borderRadius: 3,
        backgroundColor: theme.label,
        display: 'inline-block',
        fontSize: '0.7rem',
        padding: '0.3em',
        textTransform: 'uppercase',
        marginBottom: '1em',
      },
      '& > h6 ': {
        marginBottom: '0.8em',
      },
      '& > p ': {
        fontSize: '0.8rem',
        marginBottom: '1em',
      },
      '& .location-info-wrap ': {
        display: 'flex',
        alignItems: 'center',
        '& .location-info ': {
          display: 'flex',
          alignItems: 'center',
          marginRight: '2em',
          '& > svg ': {
            width: '1.5rem',
            marginRight: '0.5em',
          },
          '& > p ': {
            margin: 0,
            textTransform: 'uppercase',
            fontSize: '0.9rem',
          },
        },
      },
    },
  },
  '@media (max-width: 767px)': {
    '& .date-wrap ': {
      padding: '0.5em',
      width: '100%',
      '& > p ': {
        textAlign: 'center',
        fontSize: '1.1rem',
      },
    },
  },
});

export const eventImage = (url) => (theme) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '15rem',
  aspectRatio: ' 4 / 3',
  '@media (max-width: 767px)': {
    width: '100%',
    aspectRatio: ' 16 / 9',
    marginBottom: '1em',
  },
});
