export const header = (bgImage) => (theme) => ({
  padding: '2em 4em',
  backgroundColor: theme.primaryDark,
  marginBottom: '2em',
  '& .header-inner': {
    maxWidth: '1920px',
    margin: '0 auto',
    '& .header-breadcrumbs': {
      marginBottom: '2em',
    },
    '& .go-back-to-all': {
      display: 'none',
    },
    '& .news-title': {
      textTransform: 'uppercase',
      marginBottom: '0.3em',
    },
    '& .news-text': {
      marginBottom: '1.5em',
    },
    '& .news-date': {
      color: theme.secondaryDarker,
      fontSize: '0.9rem',
    },
    '& .news-image': {
      backgroundColor: theme.secondaryDark,
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      aspectRatio: ' 16 / 9',
    },
  },
  '@media (max-width: 991px)': {
    '& .header-inner': {
      '& .content-row': {
        flexDirection: 'column-reverse',
      },
      '& .go-back-to-all': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1em',
        '& > p': {
          margin: 0,
          textTransform: 'uppercase',
          color: theme.secondaryDark,
          fontSize: '1.2rem',
        },
        '& > i': {
          marginRight: '0.2em',
          color: theme.secondaryDark,
        },
        '&:hover': {
          '& > p': {
            color: theme.secondary,
          },
          '& > i': {
            color: theme.secondary,
          },
        },
      },
    },

    '& .header-breadcrumbs': {
      display: 'none',
    },
    '& .news-image': {
      marginBottom: '2em',
    },
  },
  '@media (max-width: 767px)': {
    padding: '2em',
  },
});

export const body = (theme) => ({
  padding: '0 4em',
  marginBottom: '2em',
  '& .news-content': {
    '& h1, h2, h3, h4, h5, h6, h7': {
      marginBottom: '0.2em',
      color: theme.secondaryDark,
      fontFamily: 'Inter-Regular',
    },
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& .video-container': {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      paddingTop: '56.25%',
      margin: '1em 0',
      '& > iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      },
    },
  },
  '@media (max-width: 767px)': {
    padding: '0 2em',
    '&  > div ': {
      maxWidth: '100%',
    },
  },
});

export const separator = (theme) => ({
  height: 1,
  backgroundColor: theme.primary,
  margin: '4em 0',
});
