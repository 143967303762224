export const tabs = (vertical, hasPadding) => [
  vertical
    ? {
        display: 'flex',
        alignItems: 'flex-start',
      }
    : {
        width: '100%',
      },
  hasPadding && {
    '& > .tabs-content': {
      padding: vertical ? '0 0 0 24px' : '24px 0 0',
    },
  },
];

export const tabsListContainer = (pills, vertical, theme) => ({
  display: 'flex',
  flexFlow: vertical ? 'column nowrap' : 'row wrap',
  width: vertical ? 'auto' : '100%',
  backgroundColor: theme.secondaryDarker,
  borderBottom: pills || vertical ? 'none' : `1px solid ${theme.primary}`,
  position: 'relative',
  zIndex: 1,
});

export const tabContainer = (pills, vertical, theme) => [
  {
    flex: '1 0 0%',
    cursor: 'pointer',
    padding: vertical ? '1em 2em' : '2em 0.5em',
    color: theme.lightText,
    transition: 'all .2s ease-in-out',
    marginBottom: vertical ? (pills ? 8 : 0) : 0,
    borderStyle: 'solid',
    borderWidth: pills || vertical ? '0' : '1px 1px 0 0',
    borderColor: pills || vertical ? 'none' : theme.darkText,
    borderRadius: pills ? 8 : 0,
    textAlign: 'center',
    textTransform: 'uppercase',

    '&:last-of-type': {
      borderRight: 0,
    },

    '&.active': pills
      ? {
          backgroundColor: theme.primary,
          color: theme.textDarkPrimary,
        }
      : vertical
      ? {
          color: theme.primary,
          borderRight: `2px solid ${theme.primary}`,
        }
      : {
          color: theme.primary,
          borderBottom: `2px solid ${theme.darkText}`,
        },

    '@media (max-width: 991px)': {
      fontSize: '1.2rem',
      padding: vertical ? '1em 2em' : '0 0.5em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  pills &&
    !vertical && {
      marginRight: 8,
    },
  vertical &&
    !pills && {
      borderRight: `2px solid ${theme.border}`,
    },
  vertical && {
    wordWrap: 'break-word',
    maxWidth: 200,
  },
];

export const arrowsWrap = (isRight) => (theme) => ({
  borderTop: `1px solid ${theme.darkText}`,
  ...(!isRight && { borderRight: `1px solid ${theme.darkText}` }),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2em 1em',
  '& > i': {
    color: theme.darkText,
    fontSize: '2.5rem',
  },
});
