import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Row, Col, EventsList, showError } from 'components';
import { getEvents } from 'services';
import { getImagePath, eventDateFormat } from 'utils';
import { header } from './styles';

const Events = () => {
  const { getText } = useTranslations();
  const [mainEvent, setMainEvent] = useState();
  const { month, day } = eventDateFormat(mainEvent?.startDate, mainEvent?.endDate);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const [res, err] = await getEvents({
      filter: '(isMain=true && isActive=true)',
    });
    if (err) return showError(err?.data);
    if (res?.totalItems > 0) setMainEvent(res?.items?.at(0));
  };

  return (
    <Row container>
      <Col>
        <section css={header(getImagePath(mainEvent))}>
          <h2>{getText('oneEcosystemEvents')}</h2>
          <div className="top-news">
            <Row horizontalGap={32} align="center">
              <Col md={6} lg={5}>
                <div className="image-holder" />
              </Col>
              <Col md={6} lg={7}>
                <div className="content-holder">
                  <p className="news-date">{`${month}, ${day}`}</p>
                  <h4 className="news-title">{mainEvent?.title}</h4>
                  <p className="news-text">{mainEvent?.description}</p>
                  <Button linkTo={`/events/${mainEvent?.id}`} type="secondary">
                    {getText('readMore')}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <EventsList />
      </Col>
    </Row>
  );
};

export default Events;
